<template>
  <div>
    <div class="sctp-container sctp-pad-tb20 container clear">
      <div class="" style="margin-top: -10px;">
        <el-breadcrumb separator=">">
          <el-breadcrumb-item>首页</el-breadcrumb-item>
          <el-breadcrumb-item>需求中心</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="sctp-bg-white sctp-pad-tb10 sctp-mar-tb10">
        <condition
          @select="onConditionSelect"
          :data="condition"
          :value="conditionDefaultValue"
        ></condition>
      </div>
      <div class="">
        <el-row :gutter="10">
          <el-col :span="18">
            <el-row :gutter="10" class="">
              <el-col :span="24" class="">
                <div class="flex flex-sb sctp-bg-white pad-lr15 pad-tb10 sctp-mar-b10">
                  <div class="flex sctp-flex-aic">
                    <el-button-group>
                      <el-button
                        size="mini"
                        @click="codeRequireOrderBy('')">综合
                      </el-button>
                      <el-button
                        size="mini"
                        @click="codeRequireOrderBy('createtime')">发布时间
                        {{
                          orderByStatus('createtime') === 0 ? '' : orderByStatus('createtime') > 0
                            ? '▲' : '▼'
                        }}
                      </el-button>
                      <el-button
                        size="mini"
                        @click="codeRequireOrderBy('minbudget')">价格
                        {{
                          orderByStatus('minbudget') === 0 ? '' : orderByStatus('minbudget') > 0
                            ? '▲' : '▼'
                        }}
                      </el-button>
                    </el-button-group>
                    <el-select
                      size="mini"
                      style="width: 150px"
                      v-model="codeRequireParam.priceRange" placeholder="请选择" clearable
                      @change="changePriceRange">
                      <el-option
                        v-for="item in codeRequireCondition.priceRange.conditions"
                        :key="item"
                        :label="item"
                        :value="item">
                      </el-option>
                    </el-select>
                  </div>
                  <el-input
                    size="mini"
                    v-model="codeRequireParam.title" placeholder="需求标题" :style="{width:'auto'}">
                    <el-button @click="() => {pageConfig.page = 1;getCodeRequireList()}"
                               slot="append"
                               icon="el-icon-search"></el-button>
                  </el-input>
                </div>
              </el-col>
              <template v-for="(codeRequire,index) in codeRequireList">
                <el-col :span="24">
                  <a target="_blank" :href="'/requirement/view/' + codeRequire.requireId">
                    <div class="sctp-bg-white code-require-item" style="overflow: hidden;">
                      <div class="pad-lr15 pad-tb10">
                        <div class="flex flex-sb fz-14">
                          <div class="sctp-ellipsis fz-18 fc-black">{{ codeRequire.title }}</div>
                          <div class="sctp-red">{{
                              codeRequire.budgetScope ? '¥' + codeRequire.minBudget + '-'
                                + codeRequire.maxBudget : '无预算'
                            }}
                          </div>
                        </div>
                        <div class="fc-normal">
                          <div class="sctp-mar-t5 flex flex-sb ">
                            <div class="sctp-flex-item-1">需求编号：{{
                                '#' + codeRequire.requireId
                              }}
                            </div>
                          </div>
                          <div class="sctp-mar-t5 flex flex-sb ">
                            <div class="sctp-flex-item-1">
                              需求类型：{{
                                codeRequire.reqTypeName
                              }}+{{ codeRequire.typeName }}
                            </div>
                            <div>
                              {{ codeRequire.hits || 0 }}人浏览
                            </div>
                          </div>
                          <div class="sctp-mar-t5 flex flex-sb ">
                            <div class="sctp-flex-item-1">
                              截至日期：{{ dateToString(codeRequire.expectDate, 'yyyy.MM.dd') }}
                              <span class="sctp-red">{{
                                  calcDay(codeRequire.expectDate)
                                }}</span>
                            </div>
                            <div>
                              {{ codeRequire.views || 0 }}人沟通
                            </div>
                          </div>
                          <div class="sctp-mar-t5 flex flex-sb ">
                            <div class="sctp-flex-item-1">
                              需求主体：{{ codeRequire.userType }}
                            </div>
                            <div>
                              <a v-if="codeRequire.favorite"
                                 class="sctp-color-main"
                                 @click.prevent="doPrivileged(favoriteConfig.onClick, codeRequire)">
                                <i class="el-icon-star-on"></i>
                                已收藏
                              </a>
                              <a v-else
                                 @click.prevent="doPrivileged(favoriteConfig.onClick, codeRequire)">
                                <i class="el-icon-star-off"></i>
                                收藏
                              </a>
                            </div>
                          </div>
                          <div class="sctp-mar-t5 sctp-ellipsis--l2 ">需求描述：<span class=""
                                                                                 style="">{{
                              codeRequire.reqDesc
                            }}</span>
                          </div>
                        </div>
                      </div>
                      <template v-if="index !== codeRequireList.length - 1">
                        <div class="sctp-pad-lr15">
                          <div class="divider"></div>
                        </div>
                      </template>
                    </div>
                  </a>
                </el-col>
              </template>
              <el-col :span="24">
                <div class="sctp-pad-tb10 sctp-mar-t10 sctp-flex sctp-flex-fe sctp-bg-white">
                  <el-pagination
                    :current-page="pageConfig.page"
                    :page-size="pageConfig.limit"
                    @size-change="pageConfig.handleSizeChange"
                    @current-change="pageConfig.handleCurrentChange"
                    :page-sizes="pageConfig.pageSizes"
                    layout="prev, pager, next, total, sizes"
                    :total="pageConfig.total">
                  </el-pagination>
                </div>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="6">
            <div class="sctp-bg-white">
              <div class="sctp-flex sctp-flex-sb sctp-pad-tb15">
                <div class="slider-card-title">推荐需求</div>
                <div></div>
              </div>
              <template v-for="(recommendItem,index) in recommendRequireConfig.data">
                <div class="sctp-bg-white pad5">
                  <a target="_blank" :key="recommendItem.requireid"
                     :href="'/requirement/view/' + recommendItem.requireid">
                    <div class="sctp-flex">
                      <el-image
                        class="sctp-mar-r10"
                        :src="getRequireTypeImg(recommendItem.reqflag)"
                        fit="cover"
                        style="width:100px;height:100px;"
                      ></el-image>
                      <div class="sctp-flex-item-1">
                        <div class="flex flex-sb flex-col full">
                          <div class="sctp-ellipsis--l3">
                            <div>{{ recommendItem.title }}</div>
                            <div class="">
                              <!-- {{ recommendItem.requireTypeName }}-->
                              {{ recommendItem.codeTypeName }}
                            </div>
                          </div>
                          <div class="sctp-font-12 sctp-mar-t5"><span>价格：</span><span
                            class="sctp-red">{{
                              recommendItem.budget_scope ? '¥' + recommendItem.minbudget + '-' + '¥'
                                + recommendItem.maxbugget : '无预算'
                            }}</span></div>
                        </div>
                      </div>
                    </div>
                    <template v-if="index !== recommendRequireConfig.data.length - 1">
                      <div class="divider"></div>
                    </template>
                  </a>
                </div>
              </template>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import {collection, demand,common} from "../../../apis";
import {differenceInDays} from 'date-fns'
const config = require('../../../resources/js/config');
export default {
  components: {
    condition: () => import('@PAGE/home/QueryMenu.vue')
  },
  data() {
    return {
      runtime:0,
      codeRequireParam: {
        reqtypeid: null,
        orderBy: null,
        smallPrice: null,
        bigPrice: null,
        priceRange: null,
        title: null,
        reqflag: null,
      },
      codeRequireCondition: {
        reqType: {
          originalConditions: [],
          conditions: []
        },
        priceRange: {
          conditions: []
        }
      },
      codeRequireList: [],
      typeType: null,
      recommendRequireConfig: {
        data: [],
        page: 1,
        limit: 5
      },
      pageConfig: {
        page: 1,
        limit: 10,
        pageSizes: [1, 2, 5, 10].map(item => item * 10),
        total: 0,
        handleSizeChange: (limit) => {
          this.pageConfig.page = 1;
          this.pageConfig.limit = limit;
          this.getCodeRequireList()
        },
        handleCurrentChange: (page) => {
          this.pageConfig.page = page;
          this.getCodeRequireList()
        }
      },
      requireTypes: [], // 需求类型
      favoriteConfig: {
        onClick: (require) => {
          if (this.favoriteConfig.collectionList.includes(require.requireId)) {
            return;
          }
          this.favoriteConfig.collectionList.push(require.requireId);
          collection.addFavorite({
            userId: (this.user && this.user.userId) || null,
            targetId: require.requireId,
            targetType: 3
          }).then(res => {
            const {retdata} = res;
            this.$set(require, 'favorite', retdata);
            if (retdata) {
              this.$message.success('收藏成功')
            } else {
              this.$message.success('取消收藏成功')
            }
          }).finally(() => {
            let index = this.favoriteConfig.collectionList.findIndex(
              item => item === require.requireId);
            if (index !== -1) {
              this.favoriteConfig.collectionList.splice(index, 1);
            }
          })
        },
        collectionList: [],
      },
    }
  },
  watch: {
    '$route'(to, from) {
      if (to.path === from.path) {
        this.loadRouterData();
        this.getCodeRequireList();
      }
    }
  },
  methods: {
    getCondition() {
      demand.condition().then(res => {
        const {retdata = [], types = [], budgets = []} = res;
        console.log("getCondition AC1111")
        this.codeRequireCondition.reqType.originalConditions = [{
          reqtypeid: null,
          typename: '全部'
        }, ...retdata];
        this.requireTypes = types;
        console.log(types)
        console.log("end")
        this.codeRequireCondition.priceRange.conditions = budgets;
      })
    },
    getCodeRequireList() {
      let {pageConfig: {page, limit}, codeRequireParam} = this;
      demand.codeRequireCenter({
        page, limit,
        orderBy: codeRequireParam.orderBy,
        minPrice: codeRequireParam.smallPrice,
        maxPrice: codeRequireParam.bigPrice,
        reqFlag: codeRequireParam.reqflag,
        title: codeRequireParam.title,
        reqTypeId: codeRequireParam.reqtypeid,
        userId: (this.user && this.user.userId) || null,
      }).then(res => {
        let {retdata = [], count = 0} = res;
        this.pageConfig.total = count;
        this.codeRequireList = retdata;
      });
    },
    codeRequireOrderBy(field) {
      let result = null;
      if (field) {
        let ret = this.orderByStatus(field);
        if (ret === 0) {
          result = {field: field, orderByType: 'ASC'};
        } else if (ret === 1) {
          result = {field: field, orderByType: 'DESC'};
        } else if (ret === -1) {
          result = null;
        }
      }
      this.codeRequireParam.orderBy = result;
      this.pageConfig.page = 1;
      this.getCodeRequireList();
    },
    subHtmlStrEllipsis: function (text, length = 50) {
      const res = text && text.replace(/<img.*?>/g, '[图片]').replace(/<.*?>/g, '').replace(/\n/g,
        ' ').replace(/ +/g, ' ');
      return (res && text.length > 50 ? res.substr(0, length) + '...' : res)
    },
    changePriceRange() {
      let {codeRequireParam: {priceRange}} = this;
      if (priceRange) {
        if (priceRange.indexOf('-') > -1) {
          this.codeRequireParam.smallPrice = priceRange.split('-')[0];
          this.codeRequireParam.bigPrice = priceRange.split('-')[1]
        }
      } else {
        this.codeRequireParam.smallPrice = null;
        this.codeRequireParam.bigPrice = null;
      }
      this.pageConfig.page = 1;
      this.getCodeRequireList();
    },
    changeReqtypeType(type) {
      this.typeType = type;
      if (type) {
        let unChange = false;
        this.codeRequireCondition.reqType.conditions = this.codeRequireCondition.reqType.originalConditions.filter(
          item => {
            unChange = unChange || (item.type === this.typeType && item.reqtypeid
              === this.codeRequireParam.reqtypeid);
            return item.type === this.typeType || !item.reqtypeid
          });
        this.codeRequireParam.reqtypeid = unChange ? this.codeRequireParam.reqtypeid : null;
        !unChange && this.getCodeRequireList()
      } else {
        this.codeRequireCondition.reqType.conditions = this.codeRequireCondition.reqType.originalConditions;
        this.getCodeRequireList()
      }
    },
    getRecommendRequire() {
      const {page, limit} = this.recommendRequireConfig;
      return this.$request.post({
        reqcode: '1130',
        reqdesc: '推荐需求',
        page: page,
        limit: limit
      }).then(res => {
        const {retdata = []} = res;
        // if (data.length === 0) {
        //   this.recommendRequireConfig.page = 1;
        //   this.getRecommendRequire();
        //   return
        // }
        this.recommendRequireConfig.data = retdata;
        this.recommendRequireConfig.page = retdata.length < limit ? 1 : page + 1
      })
    },
    onConditionSelect({label, target}) {
      console.log("onConditionSelect AC!"+label)
      console.log("Target:")
      console.log(target)
      console.log("end:type")
      const {value, type} = target;
      console.log(value)
      switch (label) {
        case '需求类型':
          this.codeRequireParam.reqflag = value;
          this.changeReqtypeType(type);
          break;
        case '需求分类':
          this.codeRequireParam.reqtypeid = value;
          this.getCodeRequireList();
          break;
        default:
          break
      }
    },
    loadRouterData() {
      const {reqtypeid, search, reqflag} = this.getQuery();
      this.codeRequireParam.reqtypeid = reqtypeid || null;
      this.codeRequireParam.title = search || null;
      this.codeRequireParam.reqflag = reqflag || null;
    },
  },
  computed: {
    orderByStatus() {
      return function (field) {
        let {orderBy} = this.codeRequireParam;
        if (orderBy && orderBy.field && orderBy.field === field) {
          if (!orderBy.orderByType || orderBy.orderByType === 'ASC') {
            return 1;
          }
          return -1;
        }
        return 0;
      }
    },
    calcDay() {
      return function (date) {
        console.log(date);
        let ret = differenceInDays(new Date(date), new Date);
        if (ret < 0) {
          return '';
        }
        return `(截至${ret === 0 ? '今' : ret}天)`;
      }
    },
    getRequireTypeImg() {
      return (reqflag) => {
        const {requireTypes} = this;
        if (requireTypes && requireTypes.length > 0) {
          const find = requireTypes.filter(item => item.id === reqflag);
          if (find && find.length > 0) {
            const first = find[0];
            return config.ADMIN_URL + first.imgurl
          }
        }
        return ''
      }
    },
    getRequireTypeName() {
      return (reqflag) => {
        const {requireTypes} = this;
        if (requireTypes && requireTypes.length > 0) {
          const find = requireTypes.filter(item => item.type === reqflag);
          if (find && find.length > 0) {
            const first = find[0];
            return first.reqname
          }
        }
        return ''
      }
    },
    conditionDefaultValue() {
      return {
        需求类型: this.codeRequireParam.reqflag,
        需求分类: this.codeRequireParam.reqtypeid
      }
    },
    condition() {
      console.log('run:'+this.runtime)
      if(this.runtime==0||this.runtime==1)
      {
        this.codeRequireCondition.reqType.conditions = this.codeRequireCondition.reqType.originalConditions;
        this.getCodeRequireList();
        this.runtime+=1;
      }
      const condition = this.codeRequireCondition.reqType.conditions;
      const requireTypes = this.requireTypes;
      console.log("condition AC!")
      console.log("condition:")
      console.log(condition)
      console.log("end")
      return [{
        label: '需求类型',
        values: [{
          title: '全部',
          value: null,
        }].concat(requireTypes.map(item => {
          item.title = item.reqname;
          item.value = item.type;
          return item
        }))
      }, {
        label: '需求分类',
        values: condition.map(item => {
          item.title = item.typename;
          item.value = item.reqtypeid;
          return item
        })
      }
      ];
    }
  },
  mounted() {
    common.viewHistory({
      userId: (this.user && this.user.userId) || null,
      id: 7,
      title: '需求任务大厅',
      type: 7
    });
  },
  beforeMount() {
    this.loadRouterData();
    this.getCondition();
    this.getRecommendRequire();
  }
}
</script>

<style scoped lang="scss">
.divider {
  height: 1px;
  border-bottom: #dddddd 1px dashed;
  margin-bottom: 8px;
  margin-top: 5px;
  width: 100%;
}

.code-require-item {
  &:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    background-color: #e5e5e5 !important;
    > div {
      //box-shadow: 0 0 10px 0 rgba(111, 111, 111, .3);
    }
  }
}
</style>
